type MapKey = number | string;
type WithId = { id?: MapKey };
type MapValue = number | string | WithId;

export class GeneralSet<T extends MapValue> {

    map: Map<MapKey, T>;

    constructor(items?: T[]) {
        this.map = new Map();

        items?.forEach(item => this.add(item));
    }

    getKey(item: T) {
        if (typeof item === "string" || typeof item === "number") {
            return item;
        } else {
            return (item as WithId).id;
        }
    }

    add(item: T) {
        const key = this.getKey(item);
        if (key) {
            this.map.set(key, item);
        } else {
            throw new Error('Cannot add elements without id');
        }
    }

    values() {
        return Array.from(this.map.values());
    }

    empty() {
        return this.map.size === 0;
    }

    size() {
        return this.map.size;
    }

    has(item: T) {
        const key = this.getKey(item);
        return key ? this.map.has(key) : false;
    }

    delete(item: T) {
        const key = this.getKey(item);
        key && this.map.delete(key);
    }

    public addImmutable(item: T): GeneralSet<T> {
        const newSet = new GeneralSet<T>();
        newSet.map = new Map(this.map);
        newSet.add(item);
        return newSet;
    }

    public addImmutableMulti(items: T[]): GeneralSet<T> {
        const newSet = new GeneralSet<T>();
        newSet.map = new Map(this.map);
        items.forEach(item => {
            newSet.add(item);
        })
        return newSet;
    }

    public deleteImmutable(item: T): GeneralSet<T> {
        const newSet = new GeneralSet<T>();
        newSet.map = new Map(this.map);
        newSet.delete(item);
        return newSet;
    }

    public deleteImmutableMulti(items: T[]): GeneralSet<T> {
        const newSet = new GeneralSet<T>();
        newSet.map = new Map(this.map);
        items.forEach(item => {
            newSet.delete(item);
        })
        return newSet;
    }
}